/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/cdk/overlay-prebuilt.css';

html,
body {
  height: 100%;
  width: 100%;
  font-family: 'SansWeb';
  font-size: calc(0.4em + 0.5vw + 0.3vh);

  color: white;
  background-color: black;
}

.startup-wrapper{
  padding-top: 2rem;

  display: flex;
  flex-direction: column;

  justify-content: center;

  height: calc(100% - 2rem);
  width: 100%;
}

.logo{
  height: 3rem;
}

.startup-text{
  text-align: center;
}

app-root {
  min-height: 100%;
  width: 100%;
  min-width: 0;
  display: flex;
}

.dark-blurry-backdrop{
  background-color: #00000050;
  backdrop-filter: blur(5px);
}

.custom-popup-container {
  border-radius: 1rem !important;
  overflow: hidden;
}

/* No button outline when a button has focus */
button:focus {
  outline: 0;
}

.tunify-snackbar {
  --mdc-snackbar-container-color: black !important;
  --mdc-snackbar-supporting-text-color: white !important;
  --mat-mdc-snack-bar-button-color: white !important;
  /*white-space: pre-wrap*/
}

/* common classes for the animation of overflowing text */


.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  width: 100%;
}

.error {
  color: #cc1400;
  border-color: #cc1400 !important;
}

/* v5 fonts */

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/inter/Inter-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/inter/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/inter/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/inter/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/inter/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}



/* v4 fonts */

@font-face {
  font-family: 'customArvo';
  src: url('./assets/fonts/arvo-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/arvo-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'customArvo';
  src: url('./assets/fonts/arvo-bolditalic-webfont.woff2') format('woff2'),
    url('./assets/fonts/arvo-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'customArvo';
  src: url('./assets/fonts/arvo-italic-webfont.woff2') format('woff2'),
    url('./assets/fonts/arvo-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'customArvo';
  src: url('./assets/fonts/arvo-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/arvo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SansWeb';
  src: url('./assets/fonts/pt_sans-web-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/pt_sans-web-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SansWeb';
  src: url('./assets/fonts/pt_sans-web-bolditalic-webfont.woff2')
      format('woff2'),
    url('./assets/fonts/pt_sans-web-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SansWeb';
  src: url('./assets/fonts/pt_sans-web-italic-webfont.woff2') format('woff2'),
    url('./assets/fonts/pt_sans-web-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SansWeb';
  src: url('./assets/fonts/pt_sans-web-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/pt_sans-web-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
